<script lang="ts" setup>
import { useBreakpoints } from "@vueuse/core";
const {
	public: { breakpoints }
} = useRuntimeConfig();
const buttonSize = computed(() => (useBreakpoints(breakpoints).greater("sm").value ? "s" : "xs"));

const { t } = useT();
const { open } = useTaoModals();
const { data: appInitData } = useAppInitData();
const route = useRoute();
const { isFinOperationAvailable } = useCashHelper();
const { isOneClickRegister } = useOtpGetFlow(true);
const { lobbyActive } = useGameNavigator({ enableHandlers: false });
const { isMobile } = useDevice();
const { stopTimeout } = useAutoOpenSignUp();

const { openDefault } = usePresetPackageController();

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const isLoggedIn = computed(() => appInitData.value?.isGuest === false);

const handleOpenCash = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "header",
		step: "view_payments_info"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
	if (!isFinOperationAvailable.value) {
		return;
	}
	openDefault();
};

const handleSignup = () => {
	stopTimeout();
	if (isOneClickRegister) {
		open("LazyOModalOneClickSignup");
		return;
	}
	open("LazyOModalSignup");
};

const handleMenugButtonClick = () => {
	emit("toggleSidebar");
};
</script>
<template>
	<AHeader data-tid="header" class="d-flex justify-content-end px-4 px-xl-7 py-2 gap-3 gap-md-4">
		<NuxtLink to="/" class="logo" data-tid="mob-logo" @click="lobbyActive">
			<NuxtImg
				v-if="isLoggedIn"
				class="img-logged"
				src="/nuxt-img/logo-icon-mob.png"
				alt="logo"
				format="webp"
				width="45"
				height="42"
			/>
			<NuxtImg
				v-else
				src="/nuxt-img/logo-icon@2x.png"
				class="guest"
				alt="logo"
				format="webp"
				width="321"
				height="136"
			/>
		</NuxtLink>

		<NuxtLink v-if="isLoggedIn && route.path !== '/'" to="/" class="back-home">
			<NuxtIcon name="20/arrow-back" filled class="back-icon" />
			<AText type="base" :modifiers="['link', 'underline']" data-tid="return-home">{{ t("Return to homepage") }}</AText>
		</NuxtLink>

		<div v-if="isLoggedIn" class="header-right d-flex gap-3 gap-md-4">
			<div v-if="!isMobile" class="notification">
				<ONotificationCenter />
			</div>
			<NuxtLink to="/game/" class="account-link" data-tid="header-avatar">
				<NuxtImg src="/nuxt-img/account/avatar.png" format="webp" alt="avatar" width="40" height="40" />
			</NuxtLink>
			<OHeaderBalance />
			<AAnimationPulse color="var(--primary)" :width="12" active>
				<AButton variant="primary" :size="buttonSize" data-tid="header-buy-btn" @click="handleOpenCash">
					{{ t("Buy") }}
				</AButton>
			</AAnimationPulse>
		</div>

		<div v-if="!isLoggedIn" class="buttons-auth header-right d-flex gap-3 gap-md-4">
			<AButton
				variant="primary"
				:size="buttonSize"
				class="login-btn"
				:modifiers="['outlined']"
				data-tid="header-login-btn"
				@click="open('LazyOModalLogin')"
			>
				{{ t("Log in") }}
			</AButton>
			<AButton
				variant="primary"
				:size="buttonSize"
				class="signup-btn"
				data-tid="header-register-btn"
				@click="handleSignup"
			>
				{{ t("Sign up") }}
			</AButton>
			<AIconButton variant="ghost" class="menu-btn" data-tid="burger-menu" @click.prevent="handleMenugButtonClick">
				<NuxtIcon name="20/burger" filled />
			</AIconButton>
		</div>
	</AHeader>
</template>

<style scoped lang="scss">
header {
	--a-header-background: var(--body-bg);
	--a-header-background-md: var(--a-header-background);

	z-index: 3;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

	.back-home {
		display: flex;
		gap: 8px;
		align-items: center;

		&:deep(.nuxt-icon) {
			font-size: 20px;
			line-height: 1;

			svg {
				vertical-align: top;
				margin: 0;
			}
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.pulse button {
		--a-button-primary-before-content: "";

		&::before {
			opacity: 1;
			background-color: transparent;
		}

		@include media-breakpoint-down(lg) {
			width: 60px;
		}
	}
}

.header-right,
.buttons-auth {
	button {
		@include media-breakpoint-down(lg) {
			&.login-btn {
				min-width: 98px;
			}

			&.signup-btn {
				display: none;
			}
		}

		@include media-breakpoint-up(lg) {
			min-width: 120px;
		}
	}
}

.header-right {
	flex-grow: 1;
	justify-content: flex-end;
	align-items: center;
}

.logo {
	display: inline-flex;
	margin-right: auto;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.guest {
		width: 112px;
		height: 48px;

		@include media-breakpoint-up(sm) {
			width: 160px;
			height: 68px;
		}
	}
}

.menu-btn {
	.nuxt-icon {
		font-size: 20px;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.account-link {
	display: flex;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.notification {
	border-radius: 4px;
	padding: 8px;
	height: 40px;
	min-width: 40px;
	display: flex;
	justify-content: center;
	background-color: var(--gray-700);

	@include media-breakpoint-down(lg) {
		display: none;
	}
}
</style>
